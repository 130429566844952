<template>
<div>
  <div class="row justify-content-center my-5" id="buttons">
    <b-button-toolbar key-nav aria-label="Opciones">
      <b-button-group class="mx-1">
        <b-button variant="secondary" @click="printReport()">
          <b-icon icon="printer-fill"/> Imprimir
        </b-button>
        <b-button variant="primary" :to="{name:'home'}">
          <b-icon icon="house-door-fill"/> Inicio
        </b-button>
        <b-button variant="light" @click="goBack()">
          <b-icon icon="arrow-return-left"/> Regresar
        </b-button>
      </b-button-group>
    </b-button-toolbar>
  </div>
  <b-container id="print" v-for="form in checkListForm" :key="form.id">
    <h5 class="text-secondary text-left font-weight-bold mt-3">
      {{ form.checkName }}
    </h5>
    <hr class="bg-primary">
    <b-table-simple hover small striped stacked="xl" responsive="lg"
                    v-for="checkList in form.checkLists" :key="checkList.id">
      <b-thead head-variant="dark">
        <b-tr>
          <b-th class="text-left">{{checkList.name}}</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-th :stacked-heading="checkList.name" class="text-white bg-dark"/>
        </b-tr>
        <!--Tabla de Campos -->
        <b-table-simple hover small striped stacked="xl" responsive="lg" caption-top bordered
                        v-for="field in checkList.checkFields" :key="field.id">
          <!--Nombre del Campo -->
          <b-thead>
            <b-tr>
              <b-th v-if="field.properties !== undefined && field.properties !== null && field.properties.length > 0"
                    class="text-center text-dark" :colspan="2 + field.properties.length">
                {{field.name}}
              </b-th>
              <b-th v-else colspan="2" class="text-center text-dark">{{field.name}}</b-th>
            </b-tr>
          </b-thead>
          <!--Nombre del Campo -->
          <b-tbody>
            <b-tr>
              <b-th :stacked-heading="field.name" class="text-center text-dark"/>
              <!-- Pripiedades-->
              <template v-if="field.properties !== undefined && field.properties !== null && field.properties.length > 0">
                <b-td class="text-center" v-for="value in field.properties" :key="value.key">
                  <a-checkbox checked disabled v-if="value.boolean === 'true' || value.boolean === '1' || value.boolean === true " >
                    {{value.value}}
                  </a-checkbox>
                  <a-checkbox  disabled v-else>
                    {{value.value}}
                  </a-checkbox>
                </b-td>
              </template>
              <!-- Pripiedades-->

              <!-- Respuestas-->
              <b-td class="text-right pr-5">
                <h6 :class="answerClass(field.answer)">
                  {{ field.answer }}
                </h6>
              </b-td>
              <!--Respuestas-->
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <!--Tabla de Campos -->
      </b-tbody>
    </b-table-simple>
    <hr class="bg-primary">
    <div class="card">
      <div class="card-body">
        <div class="row justify-content-center text-secondary">
          <!--INSPECTOR-->
          <div class="col-sm-12 col-md-6">
            <b-form-group id="inspector" label="Inspeccionó" label-for="inspector" >
              <b-form-input id="inspector" :value="form.inspector" disabled class="text-secondary"/>
            </b-form-group>
          </div>
          <!--INSPECTOR-->
          <div class="col-sm-6 col-md-3">
            <b-form-group id="date" label="Fecha" label-for="date" >
              <b-form-input id="date" :value="moment(form.date).format('DD/MM/YYYY')" disabled class="text-secondary"/>
            </b-form-group>
          </div>
          <!--INSPECTOR-->
          <div class="col-sm-6 col-md-3">
            <b-form-group id="final_hour" label="Hora" label-for="final_hour" >
              <b-form-input id="final_hour" :value="form.final_hour" disabled class="text-secondary"/>
            </b-form-group>
          </div>
          <!--INSPECTOR-->
          <div class="col-6" v-if="form.authorizer != null">
            <b-form-group id="authorizer" label="Autorizó" label-for="authorizer" >
              <b-form-input id="authorizer" :value="form.authorizer" disabled class="text-secondary"/>
            </b-form-group>
          </div>
          <!--INSPECTOR-->
          <div class="col-12" v-if="form.comments != null && form.comments != ''">
            <b-form-group id="comments" label="Comentarios" label-for="comments" >
              <b-form-textarea
                id="comments" :value="form.comments"
                rows="3"
                disabled class="text-secondary"
              />
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
  </b-container>
</div>
</template>

<script>
export default {
  name: 'spCheckReport',
  data() {
    return {
      checkListFormId: this.$route.params.checkListFormId,
      checkListFormAuxId: this.$route.params.checkListFormAuxId,
      checkListForm: [],
    }
  },
  methods: {
    answerClass(answer) {
      let style = 'text-primary font-weight-bold'
      if (answer === 'NO') {
        style = 'text-danger font-weight-bold'
      } else if (answer === 'NA') {
        style = 'text-secondary font-weight-bold'
      }
      return style
    },
    printReport() {
      window.print()
    },
    goBack() {
      this.$router.go(-1)
    },
    async getCheckListForm(checkListFormId) {
      const params = {
        include: 'checks,checkListAnswers.checkFields.checkLists,inspectors,authorizers',
      }
      await this.$store.dispatch('jv/get', [`checkListForms/${checkListFormId}`, {
        params: params,
      }])
        .then(response => {
          this.checkListForm.push(this.makeCheckStructure(response))
        })
    },
    makeCheckStructure(initialForm) {
      const finalForm = {
        id: initialForm.jsonApi.id,
        checkName: initialForm.checks.name,
        inspector: initialForm.inspectors.name,
        authorizer: (Object.keys(initialForm.authorizers).length > 0) ? initialForm.authorizers.name : null,
        date: initialForm.date,
        final_hour: initialForm.final_hour,
        comments: initialForm.comments,
        checkLists: {},
      }
      const answers = initialForm.checkListAnswers
      Object.entries(answers).forEach(([key, answer]) => {
        const field = answer.checkFields

        /* verificar si la llave correspondiente al checkList existe en finalForm, de no ser asi deberá crearse.
        En el caso de existir de agregará el campo
         */
        const checkListId = field.checkLists.jsonApi.id
        if (Object.prototype.hasOwnProperty.call(finalForm.checkLists, checkListId)) {
          finalForm.checkLists[checkListId].checkFields.push({
            name: field.name,
            answer: answer.value,
            properties: field.properties,
          })
        } else {
          finalForm.checkLists[checkListId] = {
            name: field.checkLists.name,
            checkFields: [],
          }
          finalForm.checkLists[checkListId].checkFields.push({
            name: field.name,
            properties: field.properties,
            answer: answer.value,
          })
        }

        // Final de forEach de answer.checkFields
      })
      // Final de forEach de answers
      return finalForm
    },
  },
  async mounted() {
    await this.getCheckListForm(this.checkListFormId)
    if (this.checkListFormAuxId !== undefined && this.checkListFormAuxId !== null) {
      await this.getCheckListForm(this.checkListFormAuxId)
    }
  },
}
</script>

<style scoped>
  @media print {
    /* All your print styles go here */
    #buttons { display: none !important; }
  }
</style>
